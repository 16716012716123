import React from "react";
import {Link} from "gatsby";
import Hero from "../sections/free-trial/Hero";
import Wave from "../sections/home1/Wave";
import PageWrapper from "../components/PageWrapper";
import SEO from '../components/SEO';
import Commit from "../sections/common/Commit";

const Contact = () => { 
  return (
    <>
      <PageWrapper        
        themeConfig={{
          headerClassName: "site-header--menu-left",
          headerFluid: false,

          footerStyle: "digma",
        }}>
        <SEO
          title="Contact us for more information"
          description="Send us a message and will reply to you within 24 hours"
        /> 
        <div className="container pt-20 pt-md-16 pt-lg-20 ">
          <nav className="breadcrumb" aria-label="Breadcrumb">
            <ol className="breadcrumb__list">
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/">Home</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/contact">Contact</Link>
              </li>
              </ol>
           </nav>
        </div>          
        <Hero className="position-relative pt-15 pt-md-31 pt-lg-25 pb-14 pb-md-19 pb-lg-33 position-relative overflow-hidden" />  
        <Wave color="#F7F9FC" />         
        <div className="grey d-flex align-items-center pt-0 pt-md-0 pt-lg-10 pb-8 pb-md-12 pb-lg-23">
          <div className="container">
            {/* Contact Form */}
            <div className="row">
              <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12">
                {/* contact details */}
                <div className="pt-0 pb-10 max-w-536 mx-auto">
                <h2>Let us help you on your interactive content experience</h2>
                <p>Get in touch and we can show you how easy it is to embark on your interactive content experience. Start creating an interactive experience today!</p>
                  {/* <Link to="/book-a-meeting" className="btn mt-5 btn btn-dodger-blue-2 btn-medium rounded-5 font-size-3">
                    Book A Meeting
                  </Link>                   */}
                </div>
              </div>
              <div className="d-block d-m-none col-lg-5 col-md-12 col-sm-12  col-xs-12 text-left">
                  <form
                    name="contact-form"
                    method="POST"
                    action="thank-you"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    data-aos="fade-up"
                    data-aos-duration={1100}
                    data-aos-once="true"
                  >
                    <input type="hidden" name="contact-form" value="contact-form" />
                    <h4 className="font-size-7 text-digma-dark mb-5 text-left">About you</h4>
                    {/* Company Name */}
                    <div className="form-group mb-7 position-relative">
                      <input
                        type="text"
                        name="first_name"
                        className="form-control form-control-lg bg-white rounded-5 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5 border-default-color"
                        placeholder="First Name*"
                        id="firstName"
                        required
                      />
                    </div>
                    <div className="form-group mb-7 position-relative">
                      <input
                        type="text"
                        name="last_name"
                        className="form-control form-control-lg bg-white rounded-5 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5 border-default-color"
                        placeholder="Last Name*"
                        id="lastName"
                        required
                      />
                    </div>                    
                    {/* Email */}
                    <div className="form-group mb-7 position-relative">
                      <input
                        type="email"
                        name="email"
                        className="form-control form-control-lg bg-white rounded-5 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5 border-default-color"
                        placeholder=" Your Email Address*"
                        id="email"
                        required
                      />
                    </div>
                    {/* Company Name */}
                    <div className="form-group mb-7 position-relative">
                      <input
                        type="number"
                        name="number"
                        className="form-control form-control-lg bg-white rounded-5 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5 border-default-color"
                        placeholder="Your Contact Number"
                        id="number"
                      />
                    </div>
                    {/* Company Name */}
                    <div className="form-group mb-7 position-relative">
                    <textarea
                        type="text"
                        name="message"
                        className="form-control form-control-lg bg-white rounded-5 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5 border-default-color"
                        placeholder="Question*"
                        id="position"
                        required
                      />
                    </div>                    
                    <h4 className="font-size-7 text-digma-dark mb-5 text-left">About your business</h4>
                    <div className="form-group mb-7 position-relative">
                      <input
                        type="text"
                        name="company name"
                        className="form-control form-control-lg bg-white rounded-5 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5 border-default-color"
                        placeholder="Company Name"
                        id="company"
                      />
                    </div>
                    <div className="form-group mb-7 position-relative">
                    <input
                        type="text"
                        name="company industry"
                        className="form-control form-control-lg bg-white rounded-5 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5 border-default-color"
                        placeholder="Company Industry"
                        id="position"
                      />
                    </div>
                    
                    
                    
                    <div className="button">
                      <button
                        type="submit"
                        href="/#"
                        className="btn btn-digma h-55 w-100 rounded-4"
                      >
                        Send
                      </button>
                    </div>
                  </form>
              </div>
            </div>
          </div>
        </div>
        <div style={{overflow:"hidden", background:"#f7f9fc"}}>
          <Commit />
        </div>           
      </PageWrapper>
    </>
  );
};
export default Contact;
